import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const ZieloneDrzwi = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>restaurant zielone drzwi</h1>
          <h2>Introduction</h2>
          <p>
            The green door is the complete opposite of the "My City" project.
            The website was to be created in record time, to be simple and
            minimalistic. I didn't even know what kind of food would be served
            there. In my opinion, a very clear, readable page was created.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/zielone_drzwi.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_zielone_drzwi.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default ZieloneDrzwi;

export function Head() {
  return <title>Restaurant Zielone Drzwi</title>;
}
